import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
  Button,
  Grid,
  useMediaQuery,
  InputAdornment,
  Link as MuiLink,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faSyncAlt,
  faCog,
  faToolbox,
  faGearComplexCode,
  faPresentationScreen,
} from "@fortawesome/pro-solid-svg-icons";
import parse from "html-react-parser";
import { useTheme } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Pagination from "../common/Pagination";
import * as CONFIG from "./utils/kthGlobalLabelsVars";
import SetDocumentMeta from "../common/SetDocumentMeta";

const SHOW_CATEGORIES = 6;

// Define Base API URL
const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Set ITEMS_PER_PAGE based on environment
const environment = window._env_.REACT_APP_ENVIRONMENT || "production";
const ITEMS_PER_PAGE = environment === "production" ? 10 : 5;

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 20; // Adjust this value as needed
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  };
  const date = new Date(dateString + "T00:00:00Z");
  return date.toLocaleDateString("en-US", options);
};

const KamathuoNewsInsights = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "News & Insights | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [lawContents, setLawContents] = useState([]);
  const [filteredContents, setFilteredContents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    practice_group: "",
    category: "",
    search: "",
  });
  const [sortOption, setSortOption] = useState("relevance");

  const [currentPage, setCurrentPage] = useState({});
  const [rfwelDialogOpen, setRfwelDialogOpen] = useState(false);
  const [patanalDialogOpen, setPatanalDialogOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const practiceGroup = queryParams.get("practice_group") || "";
    const category = queryParams.get("category") || "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      practice_group: practiceGroup,
      category: category,
    }));

    fetchLawContent();
  }, [location.search]);

  useEffect(() => {
    applyFilters();
  }, [filters, lawContents, sortOption]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  const fetchLawContent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}/legal/content`);
      setLawContents(response.data);
      setFilteredContents(response.data);

      // Derive unique categories from fetched law content
      const uniqueCategories = [
        ...new Map(
          response.data.map((content) => [
            content.main_category.slug,
            content.main_category,
          ])
        ).values(),
      ];

      setCategories(uniqueCategories);
      setFilteredCategories(uniqueCategories);
    } catch (error) {
      setError(
        "Failed to fetch content. Please check your network and try again."
      );
      console.error("Failed to fetch content:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    const { practice_group, category, search } = filters;
    let filtered = lawContents.filter((content) => {
      const matchesPracticeGroup =
        !practice_group ||
        (content.main_category &&
          content.main_category.practice_group === practice_group);
      const matchesCategory =
        !category ||
        (content.main_category && content.main_category.slug === category);
      const matchesSearch =
        !search ||
        content.title.toLowerCase().includes(search.toLowerCase()) ||
        content.description.toLowerCase().includes(search.toLowerCase());
      return matchesPracticeGroup && matchesCategory && matchesSearch;
    });

    const getDate = (content) =>
      new Date(content.last_revision_date || content.first_published || "");

    const importanceOrder = {
      Featured: 1,
      Important: 2,
      Standard: 3,
      Low: 4,
    };

    if (sortOption === "relevance") {
      filtered = filtered.sort((a, b) => {
        const importanceA = importanceOrder[a.importance] || 4;
        const importanceB = importanceOrder[b.importance] || 4;

        if (importanceA !== importanceB) {
          return importanceA - importanceB;
        }

        const dateA = getDate(a);
        const dateB = getDate(b);

        return dateB - dateA;
      });
    } else if (sortOption === "date") {
      filtered = filtered.sort((a, b) => {
        const dateA = getDate(a);
        const dateB = getDate(b);

        if (dateA !== dateB) {
          return dateB - dateA;
        }

        const importanceA = importanceOrder[a.importance] || 4;
        const importanceB = importanceOrder[b.importance] || 4;

        return importanceA - importanceB;
      });
    }

    setFilteredContents(filtered);
    setCurrentPage({});
  };

  const handleSearchChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: event.target.value,
    }));
  };

  const handleFilterChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setCurrentPage({});
  };

  const handleCategorySearch = (event, value) => {
    if (!value) {
      setFilteredCategories(categories);
      return;
    }

    const searchValue = value.toLowerCase();
    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(searchValue)
    );
    setFilteredCategories(filtered);
  };

  const clearFilters = () => {
    setFilters({
      practice_group: "",
      category: "",
      search: "",
    });
  };

  const getFilteredCategories = () => {
    const { practice_group } = filters;
    if (!practice_group) {
      return categories;
    }
    return categories.filter(
      (category) => category.practice_group === practice_group
    );
  };

  const handlePageChange = (contentType, page) => {
    setCurrentPage({
      ...currentPage,
      [contentType]: page,
    });
  };

  const groupedContent = filteredContents.reduce((acc, content) => {
    const type = content.content_type.name;
    const slug = content.content_type.slug;
    if (!acc[slug]) {
      acc[slug] = {
        name: type,
        items: [],
      };
    }
    acc[slug].items.push(content);
    return acc;
  }, {});

  const contentIcons = {
    "ai-patent-resource": faToolbox,
    "articles-legal": faNewspaper,
    updates: faSyncAlt,
    "articles-engr": faGearComplexCode,
    cle: faPresentationScreen,
  };

  const handleRfwelDialogOpen = () => setRfwelDialogOpen(true);
  const handleRfwelDialogClose = () => setRfwelDialogOpen(false);

  const handlePatanalDialogOpen = () => setPatanalDialogOpen(true);
  const handlePatanalDialogClose = () => setPatanalDialogOpen(false);

  const _rfwelDialog = () => {
    return (
      <>
        <Typography
          sx={{
            color: "white",
            backgroundColor: "#CC6633",
            borderRadius: "4px",
            p: 0.5,
            display: "inline-block",
            // ml: 2,
            fontSize: "small",
            cursor: "pointer",
          }}
          onClick={handleRfwelDialogOpen}
        >
          Rfwel Content
        </Typography>
        <Dialog open={rfwelDialogOpen} onClose={handleRfwelDialogClose}>
          <DialogTitle>Rfwel Engr Content</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Rfwel Engineering is our preferred AI automation vendor and
              wireless technology research consultant. We collaborate closely
              with Rfwel on complex AI model evaluation, training, integration,
              and deployment work. Rfwel's research teams offer valuable
              insights in wireless devices and standards. Click below to learn
              more about their capabilities.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" href="https://ai.rfwel.com" target="_blank">
              AI Automation
            </Button>
            <Button
              color="primary"
              href="https://wdi.rfwel.com"
              target="_blank"
            >
              Device Research
            </Button>
            <Button onClick={handleRfwelDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const _patanalDialog = () => {
    return (
      <>
        <Typography
          sx={{
            color: "white",
            backgroundColor: "#94618E",
            borderRadius: "4px",
            p: 0.5,
            display: "inline-block",
            // ml: 2,
            fontSize: "small",
            cursor: "pointer",
          }}
          onClick={handlePatanalDialogOpen}
        >
          Patanal Content
        </Typography>
        <Dialog open={patanalDialogOpen} onClose={handlePatanalDialogClose}>
          <DialogTitle>Patanal Content</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We collaborate closely with Patent Analytics, Inc, our preferred
              patent analytics vendor, greatly improving efficiency and matter
              budgets. Patent Analytics, Inc has created an AI-based patent
              analysis tool 'patanal.ai' geared towards accurate and efficient
              analysis of wireless technology patents and devices. Click below
              to learn more about their capability.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              href="https://www.patanal.ai/aboutus"
              target="_blank"
            >
              Visit Patanal
            </Button>
            <Button onClick={handlePatanalDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <Container className="link-no-decoration">
      <Typography variant="h4" sx={{ mt: 4 }}>
        News & Insights
      </Typography>
      <Typography
        component="div"
        variant="body1"
        color="textSecondary"
        paragraph
        sx={{ lineHeight: 1.8 }}
      >
        This page provides insights, content, and news on wireless patents,
        artificial intelligence, and wireless telecommunications. In addition to
        our internally-generated content, we have included select content from
        our preferred AI Automation vendor and wireless technology research
        consultant {_rfwelDialog()}, and our preferred patent analytics vendor{" "}
        {_patanalDialog()}.
      </Typography>

      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Search keywords"
            value={filters.search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: { xs: 2, sm: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Practice</InputLabel>
            <Select
              name="practice_group"
              value={filters.practice_group}
              onChange={handleFilterChange}
              label="Practice"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="patents">Patent Law</MenuItem>
              <MenuItem value="ai">Artificial Intelligence</MenuItem>
              <MenuItem value="telecom">Wireless Telecom Law</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              options={getFilteredCategories()}
              getOptionLabel={(option) => option.name}
              value={
                categories.find(
                  (category) => category.slug === filters.category
                ) || null
              }
              onChange={(event, newValue) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  category: newValue ? newValue.slug : "",
                }));
                setCurrentPage({});
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  onChange={(event) =>
                    handleCategorySearch(event, params.inputProps.value)
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.slug}>
                  {option.name}
                </li>
              )}
              ListboxProps={{
                style: {
                  maxHeight: SHOW_CATEGORIES * 48,
                  overflow: "auto",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="sort"
              name="sort"
              value={sortOption}
              onChange={handleSortChange}
            >
              <FormControlLabel
                value="relevance"
                control={<Radio />}
                label="Sort by Relevance"
              />
              <FormControlLabel
                value="date"
                control={<Radio />}
                label="Sort by Date"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Button
            onClick={clearFilters}
            startIcon={<SearchOffIcon />}
            variant="outlined"
            sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>

      {filters.search || filters.practice_group || filters.category ? (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={clearFilters}>
              Show All
            </Button>
          }
        >
          Showing results for
          {filters.search && ` search keywords = "${filters.search}"`}
          {filters.practice_group && filters.search && " & "}
          {filters.practice_group && ` practice = ${filters.practice_group}`}
          {(filters.practice_group || filters.search) &&
            filters.category &&
            " & "}
          {filters.category && ` category = ${filters.category}`}
        </Alert>
      ) : null}

      {CONFIG.RFW_HORIZDOT}

      {filteredContents.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: "10px", marginBottom: "5px" }}
          >
            Click on link to jump to content type:
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 40 }}>
            {Object.keys(groupedContent).map((slug, index) => (
              <Typography variant="body1" style={{ marginRight: 8 }} key={slug}>
                <a
                  href={`#${slug}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToElement(slug);
                  }}
                >
                  <FontAwesomeIcon
                    icon={contentIcons[slug]}
                    style={{ marginRight: "7px" }}
                  />
                  {groupedContent[slug].name}
                </a>
                {index < Object.keys(groupedContent).length - 1 && " | "}
              </Typography>
            ))}
            {(filters.practice_group === "ai" ||
              filters.practice_group === "patents") && (
              <>
                <Typography
                  variant="body1"
                  style={{ marginRight: 8 }}
                  key="ai-patent-resource"
                >
                  {Object.keys(groupedContent).length > 0 && " | "}
                  <RouterLink
                    to="/patent-ai-resources"
                    // style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <FontAwesomeIcon
                      icon={contentIcons["ai-patent-resource"]}
                      style={{ marginRight: "7px" }}
                    />
                    AI Patent Resources
                  </RouterLink>
                </Typography>
              </>
            )}
          </div>
        </>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : filteredContents.length === 0 ? (
        <Alert severity="warning" sx={{ mt: 4 }}>
          No results found for the selected filters.
        </Alert>
      ) : (
        Object.keys(groupedContent).map((slug) => {
          const contentTypeItems = groupedContent[slug].items;
          const page = currentPage[slug] || 1;
          const paginatedItems = contentTypeItems.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );

          return (
            <div key={slug}>
              <Typography variant="h5" id={slug} sx={{ mt: 6 }}>
                {groupedContent[slug].name} ({groupedContent[slug].items.length}
                )
              </Typography>
              <hr />

              {paginatedItems.map((content) => (
                <div key={content.id}>
                  <Typography variant="body2" component="div">
                    <Typography
                      component="a"
                      href={content.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        color: "black",
                        "&:hover": {
                          color: baseConfig.siteBranding.secColor,
                        },
                        transition: "color 0.3s",
                      }}
                    >
                      {content.title}
                    </Typography>{" "}
                    {content.first_published && (
                      <>
                        <CalendarTodayIcon fontSize="small" sx={{ ml: 2 }} />{" "}
                        {formatDate(content.first_published)}{" "}
                      </>
                    )}
                    {content.original_author &&
                      content.content_owner === "kth" && (
                        <MuiLink
                          component={RouterLink}
                          to={`/professionals/${content.original_author.first_name.toLowerCase()}-${content.original_author.last_name.toLowerCase()}`}
                        >
                          <PersonIcon fontSize="small" sx={{ ml: 2 }} />{" "}
                          {content.original_author.first_name}{" "}
                          {content.original_author.last_name}
                        </MuiLink>
                      )}
                    {content.content_owner === "rfw" && (
                      <Box sx={{ padding: 1, display: "inline-block" }}>
                        {_rfwelDialog()}
                      </Box>
                    )}
                    {content.content_owner === "pat" && (
                      <Box sx={{ padding: 1, display: "inline-block" }}>
                        {_patanalDialog()}
                      </Box>
                    )}
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ mt: 1, mb: 2 }}
                    >
                      {parse(content.description)}
                    </Typography>
                  </Typography>
                </div>
              ))}

              {contentTypeItems.length > ITEMS_PER_PAGE && (
                <Box sx={{ mt: 5 }}>
                  <Pagination
                    itemsCount={contentTypeItems.length}
                    pageSize={ITEMS_PER_PAGE}
                    currentPage={page}
                    onPageChange={(page) => handlePageChange(slug, page)}
                  />
                </Box>
              )}
            </div>
          );
        })
      )}

      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={{ mt: 6 }}>
            Additional Resources
          </Typography>
          <hr />
          <Button
            startIcon={<HomeRepairServiceIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/patent-ai-resources"
          >
            Patent AI Resources
          </Button>
        </Grid>
      </Grid>

      {error && <Typography color="error">{error}</Typography>}
    </Container>
  );
};

export default KamathuoNewsInsights;
