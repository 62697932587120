import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./context/AuthContext";
import baseConfigs from "./components/baseConfigs";
import loadGoogleAnalytics from "./utils/loadGoogleAnalytics";
import useIdleTimer from "./services/useIdleTimer";

import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";

const App = ({ domain }) => {
  const { user } = useAuth();
  const location = useLocation();
  const IDLE_TIMER_MINUTES = 30; // Logout if inactive for 30 minutes
  const IDLE_TIME_WARNING_SECONDS = 30; // Logout 30 seconds after warning of inactivity pops up
  const navigate = useNavigate();
  const baseConfig = baseConfigs[domain] || {};
  const [isInactivityModalOpen, setIsInactivityModalOpen] = useState(false);
  const isInactivityModalOpenRef = useRef(isInactivityModalOpen); // Use a ref to track modal state
  const [enableIdleTimer, setEnableIdleTimer] = useState(!!user);
  const BaseComponentToRender = baseConfig.BaseComponent;

  //-------------------------------------------------------------------------
  // Track Inactivity
  //-------------------------------------------------------------------------
  useEffect(() => {
    setEnableIdleTimer(!!user); //!!user makes explicity user boolean true if user is not null (if user is truthy)
  }, [user]);

  useEffect(() => {
    isInactivityModalOpenRef.current = isInactivityModalOpen; // Update the ref whenever the state changes
  }, [isInactivityModalOpen]);

  const handleTimeout = useCallback(() => {
    if (user) {
      setIsInactivityModalOpen(true);
      setTimeout(() => {
        if (isInactivityModalOpenRef.current) {
          // Check the current value from the ref
          logoutInactiveUser();
        }
      }, IDLE_TIME_WARNING_SECONDS * 1000);
    }
  }, [user]);

  // Use idle timer only when enabled
  useIdleTimer(IDLE_TIMER_MINUTES, handleTimeout, domain, enableIdleTimer);

  const logoutInactiveUser = () => {
    setIsInactivityModalOpen(false);
    alert("You have been logged out due to inactivity.");
    navigate("/logout");
  };

  const handleStayLoggedIn = () => {
    setIsInactivityModalOpen(false);
    // The act of clicking on the modal to select keep me logged in resets inactivity timer
  };

  const logoutModalClick = () => {
    setIsInactivityModalOpen(false);
    navigate("/logout");
  };

  //-------------------------------------------------------------------------
  // Google Analytics
  //-------------------------------------------------------------------------
  useEffect(() => {
    // These are set by including SetDocumentMeta component. If this component is instantiated in a component
    // we set title, meta description and keywords using values from the baseConfig of the domain
    // document.title = baseConfig.title || "Default Title";
    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute(
    //     "content",
    //     baseConfig.metaDescription || "Default description"
    //   );
    // document
    //   .querySelector('meta[name="keywords"]')
    //   .setAttribute("content", baseConfig.metaKeywords || "Default keywords");

    if (baseConfig.googleAnalyticsId) {
      loadGoogleAnalytics(baseConfig.googleAnalyticsId);
    }
  }, [baseConfig]);

  //-------------------------------------------------------------------------
  // Set Favicons
  //-------------------------------------------------------------------------
  // Set the favicons based on domain
  useEffect(() => {
    const updateFavicon = (selector, fileName) => {
      const element = document.querySelector(selector);
      const folderPath = `/static/images/${baseConfig.faviconFolder}`;
      const fullPath = `${folderPath}/${fileName}`;

      // Check if the file exists by trying to load it
      fetch(fullPath, { method: "HEAD" })
        .then((response) => {
          if (response.ok) {
            element.href = fullPath;
          } else {
            // If specific favicon is not found, and it's not the default .ico, ignore.
            // Only set a new path for favicon.ico if not found.
            if (fileName === "favicon.ico") {
              element.href = `/static/images/react-favicon/favicon.ico`;
            }
          }
        })
        .catch(console.error); // Handle errors (optional)
    };

    // Update each favicon link
    updateFavicon(`link[rel='shortcut icon']`, "favicon.ico"); // Handles both .ico updates and potential absence
    updateFavicon(`link[rel='icon'][sizes='32x32']`, "favicon-32x32.png");
    updateFavicon(`link[rel='icon'][sizes='16x16']`, "favicon-16x16.png");
    updateFavicon(`link[rel='apple-touch-icon']`, "apple-touch-icon.png");
  }, [baseConfig.faviconFolder]);

  //-------------------------------------------------------------------------
  // Build Page
  //-------------------------------------------------------------------------
  return (
    <>
      <ToastContainer />
      <BaseComponentToRender user={user} baseConfig={baseConfig} />
      {/* Dialog for inactivity timeout */}
      <Dialog
        open={isInactivityModalOpen}
        onClose={() => setIsInactivityModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Are you still there?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleStayLoggedIn} color="primary">
            Yes, Keep me logged in
          </Button>
          <Button onClick={logoutModalClick} color="secondary" autoFocus>
            No, Log me out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
