import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link as MuiLink, Paper } from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KamathuoDisclaimer = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Disclaimer | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;
  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ color: headerTextColor }}
        >
          Legal Disclaimer
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Legal Notice
        </Typography>

        <Typography paragraph>
          KamaThuo, PLLC is a professional limited liability corporation
          incorporated in Arizona.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Copyright Notice
        </Typography>
        <Typography paragraph>
          All copyrightable text and graphics, the selection, arrangement, and
          presentation of all materials (including information in the public
          domain), and the overall design of this web page are ©KamaThuo, PLLC.
          All rights reserved. Permission is granted to download and print
          materials from this website for the purpose of viewing, reading, and
          retaining for reference. Any other copying, distribution,
          retransmission, or modification of information or materials on this
          site, whether in electronic or hard copy form, without the express
          prior written permission of KamaThuo, PLLC, is strictly prohibited.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Disclaimer
        </Typography>
        <Typography paragraph>
          The materials available at this web site are for informational
          purposes only and not for the purpose of providing legal advice. It is
          not intended to create or promote an attorney-client relationship and
          does not constitute and should not be relied upon as legal advice. It
          is not intended to seek professional employment in any state where
          lawyers in the firm are not admitted to practice, or in any state
          where this website would not comply with applicable requirements
          concerning advertisements and solicitations.
        </Typography>
        <Typography paragraph>
          You should contact your attorney to obtain advice with respect to any
          particular issue or problem. Use of and access to this Web site or any
          of the e-mail links contained within the site do not create an
          attorney-client relationship between KamaThuo, PLLC and the user or
          browser. The opinions expressed at or through this site are the
          opinions of the individual author and may not reflect the opinions of
          the firm or any individual attorney.
        </Typography>

        <Typography paragraph>
          If you elect to communicate with the firm, or any of its attorneys,
          through this website, do not transmit any information about any matter
          that may involve you until the firm has agreed to represent you. This
          agreement to represent you will be in the form of a written engagement
          letter.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Contact Us
        </Typography>
        <Typography paragraph>
          To request more information about KamaThuo, PLLC, please{" "}
          <MuiLink component={RouterLink} to="/contact">
            contact us
          </MuiLink>
          .
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: headerTextColor }}>
          Advertising Statement
        </Typography>

        <Typography paragraph>
          Portions of this website may contain Attorney Advertising under the
          rules of some states. Prior results do not guarantee a similar
          outcome.
        </Typography>

        <Typography sx={{ fontStyle: "italic", color: "gray" }}>
          This Disclaimer was last revised on April 25, 2024.
        </Typography>
      </Paper>
    </Container>
  );
};

export default KamathuoDisclaimer;
