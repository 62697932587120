import React from "react";
import { Box, IconButton, Grid, Link as MuiLink } from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KamathuoCaseMgmt = () => {
  SetDocumentMeta({
    title: "Case Manangement | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12}>
        Case management authenticated path here
      </Grid>
    </Grid>
  );
};

export default KamathuoCaseMgmt;
