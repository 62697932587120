import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Toolbar,
  Typography,
  Container,
  Link as MuiLink,
  AppBar,
} from "@mui/material";

const KamathuoFooter = () => {
  return (
    <AppBar
      position="static"
      style={{
        background: "#EEE",
        boxShadow: "none",
        // minHeight: "100px",
        marginTop: "20px",
      }}
    >
      <Container>
        <Toolbar
          disableGutters
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          {/* Copyright Notice */}
          <Typography
            variant="body2"
            style={{ color: "#96858F", marginTop: "10px", marginBottom: "8px" }}
          >
            ©{new Date().getFullYear()} KamaThuo, PLLC. All rights reserved.
          </Typography>

          {/* Third Row - Legal Links */}
          <Typography
            variant="body2"
            style={{ color: "#96858F", marginBottom: "8px" }}
          >
            <MuiLink
              component={RouterLink}
              to="/privacy-policy"
              style={{
                textDecoration: "none",
                color: "#EE86B7",
                marginRight: "5px",
              }}
            >
              Privacy Policy
            </MuiLink>
            |{" "}
            <MuiLink
              component={RouterLink}
              to="/contact"
              style={{
                textDecoration: "none",
                color: "#EE86B7",
                marginRight: "5px",
              }}
            >
              Contact Us
            </MuiLink>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default KamathuoFooter;
