import React from "react";
import { AppBar, Grid, Container } from "@mui/material";
import NavbarWeb from "./NavbarWeb";

const BaseComponentWeb = ({ user, baseConfig }) => {
  const { navbarConfig, RoutesComponent, HeaderComponent, FooterComponent } =
    baseConfig;

  return (
    <>
      {/* ---------------------------Top Row -----------------------------------------*/}
      <Container>
        {/* ---Custom header w/ search button --- */}
        <HeaderComponent user={user} baseConfig={baseConfig} />
      </Container>

      {/* --------------------- Navbar & Search ---------------------------------------------- */}
      {/* Navbar with full-width background but content contained */}
      <AppBar
        position="static"
        style={{ background: "#EEE", boxShadow: "none", marginTop: "10px" }}
      >
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {/* Navbar Menu */}
              <NavbarWeb
                user={user}
                navConfig={navbarConfig}
                baseConfig={baseConfig}
              />
            </Grid>
            <Grid item>
              {/* Add left justified content here */}
              {/* <CustomSearchButton /> */}
            </Grid>
          </Grid>
        </Container>
      </AppBar>

      {/* --------------------- Body ---------------------------------------------- */}
      <Container maxWidth="x1" disableGutters>
        {/* Body content */}
        {RoutesComponent && (
          <RoutesComponent user={user} baseConfig={baseConfig} />
        )}
      </Container>

      {/* --------------------- Footer ---------------------------------------------- */}
      {/* Footer with full-width background but content contained */}
      <FooterComponent />
    </>
  );
};

export default BaseComponentWeb;
