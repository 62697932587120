import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, IconButton, Toolbar, useMediaQuery, Box } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import KamathuoGoogleSearchBox from "./KamathuoGoogleSearchBox";
import { useTheme } from "@mui/material/styles";

const KamathuoHeader = ({ user, baseConfig }) => {
  const { siteBranding } = baseConfig;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //---------------------------------------------------------------------------
  // Logo Header for Small Screens
  //---------------------------------------------------------------------------

  const _smallScreenToolbar = () => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={isSmallScreen ? 8 : "auto"}>
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={`/static/images/${siteBranding.logo}`}
                alt="Logo"
                style={{
                  maxHeight: "96px",
                  width: "auto",
                  marginTop: "5px",
                }}
              />
            </RouterLink>
          )}
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 4 : "auto"}
          container
          justifyContent="flex-end"
        >
          <IconButton
            component={RouterLink}
            to="/contact"
            sx={{ color: siteBranding.priColor }}
            aria-label="contact"
            title="Contact Us"
          >
            <MailIcon />
          </IconButton>
          {user ? (
            <IconButton
              component={RouterLink}
              to="/logout"
              sx={{ color: siteBranding.priColor }}
              aria-label="logout"
              title="Logout"
            >
              <LogoutIcon />
            </IconButton>
          ) : (
            <IconButton
              component={RouterLink}
              to="/login"
              sx={{ color: siteBranding.priColor }}
              aria-label="login"
              title="Client Login"
            >
              <LoginIcon />
            </IconButton>
          )}
        </Grid>
        {!isSmallScreen && (
          <Grid item>
            <Box sx={{ width: "300px" }}>
              <KamathuoGoogleSearchBox />
            </Box>
          </Grid>
        )}
        {isSmallScreen && (
          <Grid item xs={12}>
            <KamathuoGoogleSearchBox />
          </Grid>
        )}
      </Grid>
    );
  };

  const _largeScreenToolbar = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={`/static/images/${siteBranding.logo}`}
                alt="Logo"
                style={{
                  maxHeight: "96px",
                  width: "auto",
                  marginTop: "5px",
                }}
              />
            </RouterLink>
          )}
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : "auto"}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              {!isSmallScreen && (
                <Box sx={{ width: "300px" }}>
                  <KamathuoGoogleSearchBox />
                </Box>
              )}
            </Grid>
            <Grid item>
              <IconButton
                component={RouterLink}
                to="/contact"
                sx={{ color: siteBranding.priColor }}
                aria-label="contact"
                title="Contact Us"
              >
                <MailIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {user ? (
                <IconButton
                  component={RouterLink}
                  to="/logout"
                  sx={{ color: siteBranding.priColor }}
                  aria-label="logout"
                  title="Logout"
                >
                  <LogoutIcon />
                </IconButton>
              ) : (
                <IconButton
                  component={RouterLink}
                  to="/login"
                  sx={{ color: siteBranding.priColor }}
                  aria-label="login"
                  title="Client Login"
                >
                  <LoginIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isSmallScreen && (
          <Grid item xs={12}>
            <KamathuoGoogleSearchBox />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Toolbar>
      {isSmallScreen ? _smallScreenToolbar() : _largeScreenToolbar()}
    </Toolbar>
  );
};

export default KamathuoHeader;
