import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportFieldsMapping } from "./patanalaiFieldsForExport";

//---------------------------------------------------------------------------------------
// Legal Disclaimer
//---------------------------------------------------------------------------------------
const legalDisclaimer = (doc) => {
  doc.addPage(); // Ensure Legal Disclaimer is on a new page

  const startY = 30; // Where to start relative to top of the page
  const startX = 14; // where to start relative to the left of the page

  doc.setFontSize(18); // Set font-size for heading

  doc.text("Legal Disclaimer", startX, startY);

  doc.setFontSize(16); // Set font-size for rest of document

  doc.setFontSize(12);
  const lineHeight = 10; // Approximate line height in mm for the current font size

  // Render the first paragraph
  let currentY = startY + 10;
  doc.text(
    "This report DOES NOT REPRESENT A LEGAL OPINION. For legal analysis talk to a patent attorney.",
    startX,
    currentY
  );

  // Calculate position for the next section with links
  currentY += lineHeight * 1; // Increment position by one lines
  const introTextWidth = doc.getTextWidth(
    "Firms experienced in strategic analysis of wireless patents include, for example, "
  );
  doc.text(
    "Firms experienced in strategic analysis of wireless patents include, for example, ",
    startX,
    currentY
  );

  // Set text color to blue for links
  doc.setTextColor(0, 0, 255); // RGB color for blue

  const kamaThuoText = "Kama Thuo, PLLC, ";
  const kamaThuoX = startX + introTextWidth;
  doc.textWithLink(kamaThuoText, kamaThuoX, currentY, {
    url: "https://www.kamathuo.com/patents",
  });

  const perkinsText = "Perkins Coie, LLP";
  const perkinsX = kamaThuoX + doc.getTextWidth(kamaThuoText);
  doc.textWithLink(perkinsText, perkinsX, currentY, {
    url: "https://www.perkinscoie.com/en/practices/intellectual-property-law/index.html",
  });

  // Set text color back to black for regular text
  doc.setTextColor(0, 0, 0); // RGB color for black

  const restText = ", among others.";
  const restTextX = perkinsX + doc.getTextWidth(perkinsText);
  doc.text(restText, restTextX, currentY);

  currentY += lineHeight;
  doc.text(
    "You can also search your local lawyer referral service.",
    startX,
    currentY
  );

  // Adding the Terms of Service link
  currentY += lineHeight * 2;
  const termsIntroText = "Please review our ";
  doc.text(termsIntroText, startX, currentY);

  // Terms of Service link in blue
  doc.setTextColor(0, 0, 255); // Blue
  const termsX = startX + doc.getTextWidth(termsIntroText);
  doc.textWithLink("Terms of Service", termsX, currentY, {
    url: "https://www.patentanalytics.com/terms-of-service",
  });

  // Continuing the rest of the text in black
  doc.setTextColor(0, 0, 0); // Black
  const termsOutroText =
    " for more details, and take particular note of the DISCLAIMER OF WARRANTIES section.";
  const termsOutroX = termsX + doc.getTextWidth("Terms of Service");
  doc.text(termsOutroText, termsOutroX, currentY);
};

//---------------------------------------------------------------------------------------
// Footer Text
//---------------------------------------------------------------------------------------
const footerText = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  doc.setTextColor(150); // Muted grey color
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.textWithLink(
      "This report was automatically generated at patanal.ai.",
      14,
      doc.internal.pageSize.height - 10,
      { url: "https://www.patanal.ai", color: "blue", textDecoration: "none" }
    );
  }
};

//---------------------------------------------------------------------------------------
// Export One Worksheet to PDF
//---------------------------------------------------------------------------------------
export const exportToPDF = (data, type) => {
  const startX = 14;

  const doc = new jsPDF({
    orientation: "landscape",
  });

  // Cover Page
  doc.setFontSize(22);
  doc.text("Patanal Analysis Worksheet", startX, 30);
  doc.setFontSize(16);
  doc.text(`${type} report`, startX, 50);
  doc.text(`Date: ${new Date().toLocaleDateString()}`, startX, 60);

  // Add Table
  const fields = exportFieldsMapping[type] || Object.keys(data[0] || {});
  const tableData = data.map((item) =>
    fields.map((field) => item[field] || "")
  );
  doc.autoTable({
    head: [fields],
    body: tableData,
    startY: 70,
  });

  legalDisclaimer(doc); // Add Legal Disclaimer as last page
  footerText(doc); // Add footer to all pages
  doc.save(`${type}-worksheet.pdf`);
};

//---------------------------------------------------------------------------------------
// Export All WorkSheets to PDF
//---------------------------------------------------------------------------------------
export const exportAllToPDF = (worksheet) => {
  const startX = 14;

  const doc = new jsPDF({
    orientation: "landscape",
  });

  Object.entries(worksheet).forEach(([type, data], index) => {
    if (index !== 0) {
      doc.addPage();
    }

    // Cover Page for each type
    doc.setFontSize(22);
    doc.text("Patanal Analysis Worksheet", startX, 30);
    doc.setFontSize(16);
    doc.text(`${type} report`, startX, 50);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, startX, 60);

    // Check if there is data to display
    if (data.length > 0) {
      // There is data, render the table
      const fields = exportFieldsMapping[type] || Object.keys(data[0] || {});
      const tableData = data.map((item) =>
        fields.map((field) => item[field] || "")
      );
      doc.autoTable({
        head: [fields],
        body: tableData,
        startY: 70,
      });
    } else {
      // No data, display a message instead of the table
      doc.setFontSize(12);
      doc.text("No saved worksheet items.", startX, 80);
    }
  });

  legalDisclaimer(doc); // Add Legal Disclaimer as last page
  footerText(doc); // Add footer to all pages
  doc.save("All-worksheets.pdf");
};
