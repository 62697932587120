import React from "react";
import { Typography, Container, Paper, Link } from "@mui/material";

const AboutPatentAnalytics = () => {
  return (
    <Container maxWidth="md" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h5" gutterBottom>
          About Us
        </Typography>

        <Typography paragraph>
          Patent Analytics, Inc., provides patent, technology, and market
          analysis services for wireless patent valuation, portfolio sales &
          licensing, patent acquisition, and litigation support.
        </Typography>

        <Typography paragraph>
          We are training AI models on U.S. wireless technology patents,
          wireless standards, wireless devices, and components for our patent
          analysis AI tool. The tool will be used to augment our SEP & non-SEP
          patent analysis support services. Although Non-SEP AI training
          currently limited to antenna structures, we provide strategic
          consulting on other radio technologies including RFICs, front-end
          modules, and wireless infrastructure components. Learn more at{" "}
          <Link href="https://www.patanal.ai" target="_blank">
            patanal.ai
          </Link>
          .
        </Typography>

        <Typography paragraph>
          The Patent Analytics, Inc. team collaborates closely with Rfwel
          Engineering, LLC (an Arizona Licensed Engineering firm) for{" "}
          <Link href="https://ai.rfwel.com" target="_blank">
            AI automation support
          </Link>
          , and{" "}
          <Link href="https://wdi.rfwel.com" target="_blank">
            wireless device & technology research
          </Link>
          ; and with Kama Thuo, PLLC (a U.S. law firm) for{" "}
          <Link href="https://www.kamathuo.com/patents" target="_blank">
            patent law counseling
          </Link>{" "}
          and{" "}
          <Link href="https://www.kamathuo.com/ai" target="_blank">
            AI counseling.
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default AboutPatentAnalytics;
