import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReusableTitle from "../common/ReusableTitle";
import { faTowerBroadcast } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";

const KamathuoServicesTelecom = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Wireless Telecommunications Law",
    description: "",
    keywords: "",
  });

  // Define the content for the body
  const bodyContent = [
    {
      contentId: "telecom",
      image: "/kth-head-4-telecomm.png",
      title: "Wireless Telecommunications Law",
      text: "Kama Thuo, PLLC provides strategic counsel in wireless infrastructure transactions, spectrum advocacy, and regulatory issues. Understanding both technical aspects and legal frameworks allows Kama Thuo to effectively assist clients in navigating regulatory environments, licensing, and in compliance issues.",
      imagePosition: "left",
      link: "/news-insights?practice_group=telecom",
      linkExternal: false,
    },
    {
      contentId: "telecom-litigation",
      image: "/wireless-regs.png",
      title: "FCC",
      text: "Support in FCC enforcement actions and equipment authorization.",
      imagePosition: "right",
      link: "/news-insights?practice_group=telecom",
      linkExternal: false,
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Wireless Telecom Law"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faTowerBroadcast }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?practice_group=telecom"
          >
            News & Insights: TELECOM
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KamathuoServicesTelecom;
