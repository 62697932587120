// navbarConfig.js
export const patentAnalyticsNavbarConfig = [
  {
    title: "Wireless Reports",
    path: "/wireless-reports",
    authRequired: true,
    children: [],
  },
  {
    title: "Patent Services",
    path: "/services",
    authRequired: true,
    children: [
      {
        title: "Prior Art Search",
        path: "/prior-art-search",
      },
      {
        title: "Device Research",
        path: "/device-research",
      },
    ],
  },
  // ... more menu items
];
