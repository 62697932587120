import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Chip,
  Link as MuiLink,
  Button,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ReusableTitle from "../common/ReusableTitle";
import { faTag } from "@fortawesome/pro-solid-svg-icons";

const KamathuoAiPatents = ({ baseConfig }) => {
  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="AI in Patent Analysis"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faTag }}
        />

        {/* @TODO - ADD: We work closely with our patent analytics vendor (Patent Analytics, Inc) and levarage their patent analytics AI tool (patanal.ai). */}
        <Box sx={{ mt: 2, mb: 4 }}>
          <ReusableTitle
            title="Using AI for Patent Analysis"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Kama Thuo, PLLC leverages AI to enhance the efficiency and
            effectiveness of{" "}
            <MuiLink component={RouterLink} to="/patents">
              patent analysis
            </MuiLink>
            , offering services like claim amendment studies for targeted
            prosecution, competitive landscape analysis, licensing target
            search, patent portfolio optimization, and technology scouting. We
            also help develop strategies to protect AI-related innovations.
            Please{" "}
            <MuiLink component={RouterLink} to="/contact">
              contact us
            </MuiLink>{" "}
            to discuss how we can help.
          </Typography>

          <Grid container spacing={1} sx={{ mt: 2, ml: 3 }}>
            <Grid item>
              <Chip label="Targeted Prosecution" />
            </Grid>
            <Grid item>
              <Chip label="Licensing Target Search" />
            </Grid>
            <Grid item>
              <Chip label="Patent Acquisition Search" />
            </Grid>
            <Grid item>
              <Chip label="Clearance Search" />
            </Grid>
            <Grid item>
              <Chip label="Technology Landscape Study" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <ReusableTitle
            title="Developing and Evaluating AI Applications for Patent Analysis"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            In collaboration with our AI Automation vendor{" "}
            <MuiLink href="https://ai.rfwel.com/" target="_blank">
              Rfwel Engineering, LLC
            </MuiLink>
            , we assist clients evaluate AI applications for accuracy, and
            train/fine-tune models based on legal analysis of generated outputs.
          </Typography>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?category=ai-training-fine-tuning"
          >
            Learn More: Training
          </Button>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?category=ai-evaluation"
          >
            Learn More: Evaluation
          </Button>
          <Grid container spacing={1} sx={{ mt: 3, ml: 3 }}>
            <Grid item>
              <Chip label="AI Model Training" />
            </Grid>
            <Grid item>
              <Chip label="Reinforcement Learning" />
            </Grid>
            <Grid item>
              <Chip label="AI Output Evaluation" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default KamathuoAiPatents;
