import React from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import NotFound from "../NotFound";

import PartsFinderHome from "./PartsFinderHome";
import PartsFinder from "./PartsFinder";
import ProductSearch from "./ProductSearch";
import ProductDetailDashboard from "./ProductDetailDashboard";
import ContactUsForm from "../common/ContactUsForm";
import { useRoutes } from "react-router-dom";

const PartsFinderRoutes = ({ user = "", baseConfig }) => {
  let routes = useRoutes([
    {
      path: "/",
      element: user ? <PartsFinderHome /> : <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <LoginForm termsLink="#" linkTarget="_self" />,
    },
    { path: "/logout", element: <Logout /> },
    { path: "/not-found", element: <NotFound /> },
    {
      path: "/contact",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    //--------- Authenticated Paths --------------------------------------------------------
    //---- DEVICES -----
    {
      path: "/product-search",
      element: user ? <ProductSearch /> : <Navigate to="/login" />,
    },
    {
      path: "/product-detail",
      element: user ? <ProductDetailDashboard /> : <Navigate to="/login" />,
    },
    {
      path: "/product-detail/:sku",
      element: user ? <ProductDetailDashboard /> : <Navigate to="/login" />,
    },
    {
      path: "/find-parts",
      element: user ? <PartsFinder /> : <Navigate to="/login" />,
    },
  ]);
  return routes;
};

export default PartsFinderRoutes;
