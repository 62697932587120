import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link as MuiLink,
  Grid,
  Paper,
  TextField,
  Button,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  Container,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import GetAppIcon from "@mui/icons-material/GetApp";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Carousel from "react-material-ui-carousel";
import ImageCarousel from "../common/ImageCarousel";
import StorageIcon from "@mui/icons-material/Storage";
import axios from "axios";
import http from "../../services/httpService";
import { RoomPreferences } from "@mui/icons-material";
import RestrictedAccessPrompt from "../common/RestrictedAccessPrompt";
import RadioModuleUsage from "./RadioModuleUsage";
import ChipUsage from "./ChipUsage";
import { useAuth } from "../../context/AuthContext";
import { useWorksheet } from "../../context/WorksheetContext";

// Define Base API URL
const apiUrlPatanal = `${window._env_.REACT_APP_PATANALAI_API_URL}`; // Patanal API Endpoint

const DeviceDetailDashboard = () => {
  const { user } = useAuth();
  const { worksheet, addToWorksheet } = useWorksheet();
  const devicesInWorksheet = worksheet.devices; // Access the 'devices' array from the worksheet object
  const deviceSource = `Dev Detail`;
  const { devid: urlDevid } = useParams();
  const navigate = useNavigate();
  const [devid, setDevid] = useState("");
  const [device, setDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(false);
  const [showRadioModuleUsage, setShowRadioModuleUsage] = useState(false);
  const [showChipUsage, setShowChipUsage] = useState(false);

  //----------------------------------------------------------------------------
  // Set devid and Get Device
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (urlDevid) {
      setDevid(urlDevid);
      fetchDevice(urlDevid);
    }
  }, [urlDevid]);

  const _handleUpdateDevid = (id) => {
    const newDevid = id.trim();
    // Clear RadioModuleUsage when change devid
    setShowRadioModuleUsage(false);
    navigate(`/devices/device-detail/${newDevid}`);
  };

  const fetchDevice = async (id) => {
    setIsLoading(true);
    setError(null); // Reset previous errors
    setIsEmpty(false); // Reset the empty state
    setInitialSearchPerformed(true); // indicate that a search has been attempted
    try {
      const headers = {
        Authorization: `JWT ${http.getCookie("access_token")}`,
      };
      // Example: /devices?devid=WDI-TEST-DEVID-001
      const response = await axios.get(`${apiUrlPatanal}/devices?devid=${id}`, {
        headers,
      });
      // console.log(response.data.results[0]);
      if (response.data.results.length === 0) {
        setDevice(null);
        setIsEmpty(true); // Set empty state when no results are found
      } else {
        setDevice(response.data.results[0]);
        setIsEmpty(false);
      }
    } catch (error) {
      setError(
        "Failed to fetch device details. Please check your network and try again."
      );
      setDevice(null);
      console.error("Failed to fetch device details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //-------------------------------------------------------------------------------
  // Render Device Search Button
  //-------------------------------------------------------------------------------
  const _handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior (page refresh)
    _handleUpdateDevid(devid);
  };

  const _renderDevidSearch = () => {
    // Check if the device is already in the worksheet
    const deviceInWorksheet = devicesInWorksheet.some((d) => d.devid === devid);

    return (
      // Put everything in a form so can use enter to submit
      <form onSubmit={_handleSearchSubmit}>
        {/* @TODO -> Prompts user for MPN but using Devid here. Although devid usually same as MPN this is not always the case! */}
        <TextField
          label="Device MPN"
          variant="outlined"
          value={devid}
          onChange={(e) => setDevid(e.target.value)}
          // fullWidth
          InputProps={{
            style: { backgroundColor: "white" },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit" // Button submits form
          startIcon={<ChangeCircleIcon />}
          variant="contained"
          // onClick={() => fetchDevice(devid)}
          onClick={() => _handleUpdateDevid(devid)}
          disabled={isLoading || devid.trim() === ""}
          sx={{
            mt: 2,
            ml: 2,
            backgroundColor: "#88BBD6",
            "&:hover": { backgroundColor: "#71a5c7" },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : urlDevid ? (
            "Change Device"
          ) : (
            "Get Device"
          )}
        </Button>

        {deviceInWorksheet ? (
          <Button
            disabled
            sx={{ mt: 2, ml: 2 }}
            style={{ backgroundColor: "green", color: "white" }}
          >
            Added to Worksheet
          </Button>
        ) : (
          <Button
            variant="outlined"
            startIcon={<StorageIcon />}
            onClick={() => addToWorksheet(device, "devices", deviceSource)}
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          >
            Add to Worksheet
          </Button>
        )}

        <Button
          startIcon={<SearchIcon />}
          variant="outlined"
          sx={{
            mt: 2,
            ml: 2,
            backgroundColor: "white",
            "&:hover": { backgroundColor: "#f0f0f0" },
          }}
          href="/devices/device-search"
        >
          Search Devices
        </Button>
      </form>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Images Card
  //-------------------------------------------------------------------------------
  const autoPlay = false;
  const _renderBasicInfoCard = () => {
    return (
      <Card variant="outlined">
        {/* --- Brand & Category | Brand | Category Links --- */}
        <CardContent>
          <Button
            startIcon={<FilterAltIcon />}
            variant="outlined"
            size="small"
            sx={{ ml: 2, mb: 1 }}
            component={Link}
            to={`/devices/device-search?brand=${device.brand_name}&category=${device.category_name}`}
          >
            {device.brand_name} {device.category_name}
          </Button>
          <Button
            startIcon={<FilterListIcon />}
            variant="outlined"
            size="small"
            sx={{ ml: 2, mb: 1 }}
            component={Link}
            to={`/devices/device-search?brand=${device.brand_name}`}
          >
            All {device.brand_name} Devices
          </Button>
          <Button
            startIcon={<FilterListIcon />}
            variant="outlined"
            size="small"
            sx={{ ml: 2, mb: 1 }}
            component={Link}
            to={`/devices/device-search?category=${device.category_name}`}
          >
            All {device.category_name}
          </Button>
        </CardContent>

        {/* --- Device Title, MPN, Brand ---  */}
        <CardContent>
          <Typography variant="subtitle1">
            {device.brand_name} {device.mpn}
          </Typography>
          <Typography variant="h5">{device.title}</Typography>
        </CardContent>

        {/* --- Image Carousel --- */}
        {device && device.images.length > 0 && (
          <Grid container spacing={2} className="link-no-decoration">
            <Grid item xs={12} sm={6} md={3} sx={{ ml: 2, mb: 2 }}>
              <ImageCarousel images={device.images} />
            </Grid>
          </Grid>
        )}

        {/* Change Devices/ Search Devices Button when we have results */}
        <Grid container spacing={2} className="link-no-decoration">
          <Grid item xs={12} sx={{ mt: 2, ml: 2, mb: 2 }}>
            {_renderDevidSearch()}
          </Grid>
        </Grid>

        {/* --- Variants --- */}
        {device && device.variants.length > 0 && (
          <CardContent>
            <Typography variant="h6">Variants</Typography>
            {device.variants.map((variant) => (
              <Typography key={variant.mpn}>
                {variant.mpn}: {variant.name}
              </Typography>
            ))}
          </CardContent>
        )}

        {/* --- Declared Patents --- */}
        {device && device.declared_patents && (
          <CardContent>
            <Typography variant="h6">Declared Patents</Typography>
            <Typography>
              {device.declared_patents.map((pat, index, array) => (
                <span key={pat.patent_number}>
                  {/* @TODO Make this TO to use navigate instead of href */}
                  <MuiLink href={`/patents/patent-search/${pat.patent_number}`}>
                    {pat.patent_number}
                  </MuiLink>
                  {/* Add a comma if it's not the last item */}
                  {index < array.length - 1 ? ", " : ""}
                </span>
              ))}
            </Typography>
          </CardContent>
        )}
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render FCC ID Card
  //-------------------------------------------------------------------------------
  const _renderFccIdCard = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6">FCC ID</Typography>
          <Typography>{device.fccId}</Typography>
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Subcomponents Card
  //-------------------------------------------------------------------------------
  // Create a map from MPN to variant name for quick lookup
  // Check if `device.variants` is an array before calling `.reduce()`
  const variantMpnToVariantNameMap =
    device && Array.isArray(device.variants)
      ? device.variants.reduce((acc, variant) => {
          acc[variant.mpn] = variant.name;
          return acc;
        }, {})
      : {};

  const _renderSubcomponentsCard = () => {
    if (!device.subcomponents || device.subcomponents.length === 0) {
      return null;
    }

    // Group subcomponents by category
    const groupedByCategory = device.subcomponents.reduce((acc, sub) => {
      const { sub_component_category } = sub;
      if (!acc[sub_component_category]) {
        acc[sub_component_category] = [];
      }
      acc[sub_component_category].push(sub);
      return acc;
    }, {});

    // Split categories into columns
    const categories = Object.keys(groupedByCategory);
    const columns = [[], []]; // Assuming two-column layout
    categories.forEach((category, index) => {
      columns[index % 2].push(category);
    });

    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Subcomponents
          </Typography>
          <Grid container spacing={2}>
            {columns.map((columnCategories, colIndex) => (
              <Grid item xs={12} md={6} key={colIndex}>
                {columnCategories.map((category) => (
                  <Box key={category}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                    >
                      {category}
                    </Typography>
                    {groupedByCategory[category].map((sub, subIdx) => (
                      <Box key={sub.sub_component_devid} marginBottom={2}>
                        <Link
                          key={sub.sub_component_devid}
                          to={`/devices/device-detail/${sub.sub_component_devid}`}
                        >
                          <Typography variant="body1">
                            {sub.sub_component_mpn}: {sub.sub_component_name}
                          </Typography>
                        </Link>

                        {sub.sub_component_applies_to_variants.length > 0 && (
                          <Typography variant="body2" gutterBottom>
                            Applies to these variants:
                            {sub.sub_component_applies_to_variants
                              .map((mpn, varIdx) => {
                                const variantName =
                                  variantMpnToVariantNameMap[mpn] ||
                                  "Unknown Variant";
                                return (
                                  <Tooltip
                                    key={varIdx}
                                    title={variantName}
                                    placement="top"
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline dotted",
                                        margin: "0 4px",
                                      }}
                                    >
                                      {mpn}
                                    </span>
                                  </Tooltip>
                                );
                              })
                              .reduce(
                                (acc, x) =>
                                  acc === null ? [x] : [...acc, ", ", x],
                                null
                              )}
                          </Typography>
                        )}
                        {subIdx < groupedByCategory[category].length - 1 && (
                          <hr />
                        )}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Radio Module Usage
  //-------------------------------------------------------------------------------

  const _handleShowRadioModuleUsage = () => {
    setShowRadioModuleUsage(true);
  };

  const _renderRadioModuleUsage = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Radio Module Usage
          </Typography>
          <Button
            startIcon={<GetAppIcon />}
            onClick={_handleShowRadioModuleUsage}
            variant="outlined"
            color="primary"
            sx={{ mb: 2 }}
          >
            Get Radio Module Usage
          </Button>
          {showRadioModuleUsage && (
            <RadioModuleUsage
              devid={device.devid}
              loadUsage={showRadioModuleUsage}
            />
          )}
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Chip Usage
  //-------------------------------------------------------------------------------

  const _handleShowChipUsage = () => {
    setShowChipUsage(true);
  };

  const _renderChipUsage = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Chip Usage
          </Typography>
          <Button
            startIcon={<GetAppIcon />}
            onClick={_handleShowChipUsage}
            variant="outlined"
            color="primary"
            sx={{ mb: 2 }}
          >
            Get Chip Usage
          </Button>
          {showChipUsage && (
            <ChipUsage devid={device.devid} loadUsage={showChipUsage} />
          )}
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Standards Card
  //-------------------------------------------------------------------------------
  const _renderStandardsCard = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6">Wireless Standards</Typography>
          *****Standards + STD REvisions w/ LINKS to WDI standards Detail
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Standards Card
  //-------------------------------------------------------------------------------
  const _renderLegalEventsCard = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Device Family & Subcomponent Legal Events
          </Typography>
          <Typography sx={{ mb: 2 }}>
            ***Family Litigation, ITC Exclusions, FCC Events, Licensing.
          </Typography>
          <RestrictedAccessPrompt />
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render References
  //-------------------------------------------------------------------------------
  const _renderReferencesCard = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6">References</Typography>
          <Box>
            {device.refdocs.map((doc) => (
              <MuiLink
                key={doc.refdoc}
                href={doc.refdoc}
                target="_blank"
                rel="noopener"
              >
                {doc.refdoc_label}
              </MuiLink>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Results Cards
  //-------------------------------------------------------------------------------
  const _renderResultsCards = () => {
    if (isLoading) return <CircularProgress size={24} />;
    if (!isLoading && error) return <Alert severity="error">{error}</Alert>;
    if (!isLoading && initialSearchPerformed && isEmpty)
      return (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="warning">
            No matching devices found. Please try a different MPN or{" "}
            <MuiLink href="/devices/device-search">Search</MuiLink>.
          </Alert>
        </Grid>
      );
    return (
      <>
        {/* Images Box */}
        <Grid item xs={12}>
          {device && _renderBasicInfoCard()}
        </Grid>

        {/* Standards Box */}
        {device && device.standards.lenght > 0 && (
          <Grid item xs={12} sm={6}>
            {_renderStandardsCard()}
          </Grid>
        )}

        {/* FCC ID Box */}
        {device && device.fcc_id && (
          <Grid item xs={12} sm={6}>
            {_renderFccIdCard()}
          </Grid>
        )}

        {/* Subcomponents Box */}
        {device && device.subcomponents.length > 0 && (
          <Grid item xs={12}>
            {_renderSubcomponentsCard()}
          </Grid>
        )}

        {device && device.category_name === "Radio Modules" && (
          <Grid item xs={12}>
            {_renderRadioModuleUsage()}
          </Grid>
        )}

        {device && device.category_name === "SOC" && (
          <Grid item xs={12}>
            {_renderChipUsage()}
          </Grid>
        )}

        {/* Legal Events Box */}
        {device && (
          <Grid item xs={12} sm={6}>
            {_renderLegalEventsCard()}
          </Grid>
        )}

        {/* References Box */}
        {device && device.refdocs.length > 0 && (
          <Grid item xs={12} sm={6}>
            {_renderReferencesCard()}
          </Grid>
        )}
      </>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Full Dashboard
  //-------------------------------------------------------------------------------

  return (
    <Grid container spacing={2} className="link-no-decoration">
      {/* Device Search - Search button visible when no device otherwise search to change device visible inside results box*/}
      {!device && (
        <Container maxWidth="lg" className="link-no-decoration">
          <Paper
            elevation={3}
            sx={{ p: 3, margin: "auto", maxWidth: "90%", mt: 1 }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              Get Device Details
            </Typography>
            <Typography
              variant="caption" // Smaller text size
              align="center"
              display="block" // Ensures the text is on its own line
              sx={{
                color: "text.secondary", // Muted color
                mb: 3,
              }}
            >
              (Device data provided by{" "}
              <MuiLink
                href="https://wdi.rfwel.com/wireless-devices-info"
                target="_blank"
                // rel="noopener noreferrer"
              >
                Rfwel WDI
              </MuiLink>
              )
            </Typography>

            <Grid
              container
              spacing={2}
              // justifyContent="center"
              align="center"
              // sx={{ mt: 2, mb: 2 }}
            >
              <Grid item xs={12} sx={{ ml: 2, mb: 2 }}>
                {_renderDevidSearch()}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}

      <Grid container spacing={2} sx={{ mt: 4 }}>
        {_renderResultsCards()}
      </Grid>
    </Grid>
  );
};

export default DeviceDetailDashboard;
