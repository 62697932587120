import React from "react";
import { useMediaQuery, Typography, Grid, Paper, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ReusableTitle from "./ReusableTitle";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: "auto",
  maxWidth: "100%", // Keep 100% for small screens to use the full width
}));

const StyledImageGrid = styled(Grid)(({ theme, imageposition }) => ({
  display: "flex",
  justifyContent: "center", // Align image vertically
  alignItems: imageposition === "left" ? "flex-start" : "flex-end", // Align image horizontally based on imagePosition
  [theme.breakpoints.down("sm")]: {
    alignItems: "center", // Center image on small screens
  },
  overflow: "hidden", // Prevent image overflow
}));

const StyledImg = styled("img")({
  width: "100%", // Make image responsive
  maxWidth: "350px",
  maxHeight: "300px",
  cursor: "pointer", // Make the image appear clickable
});

// Scroll to element function - to scroll to anchor within the same page
const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const BodyContentBlock = ({ content, baseConfig }) => {
  const { contentId, image, text, title, imagePosition, link, linkExternal } =
    content;
  const { priColor, buttColor, buttHoverColor } = baseConfig.siteBranding;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const contentMaxWidth = theme.breakpoints.values.lg - 44; // Use the theme's large breakpoint as the max width (subtract 44 to align to header)

  // Decide link component and props based on the type of link
  let LinkComponent;
  let linkProps;

  if (linkExternal) {
    LinkComponent = "a";
    linkProps = { href: link, target: "_blank", rel: "noopener noreferrer" };
  } else if (link.startsWith("#")) {
    LinkComponent = "a"; // Use 'a' tag for anchor links
    linkProps = {
      href: link,
      onClick: (e) => {
        e.preventDefault();
        scrollToElement(link.substring(1));
      },
    };
  } else {
    LinkComponent = RouterLink;
    linkProps = { to: link };
  }

  return (
    <StyledPaper
      elevation={isSmallScreen ? 0 : 1}
      sx={{
        maxWidth: isSmallScreen ? "100%" : `${contentMaxWidth}px`,
        marginTop: "20px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="stretch" // Make items fill the container height
      >
        {/* Image section */}
        <StyledImageGrid
          item
          xs={12}
          sm={imagePosition === "left" ? 6 : 5}
          lg={imagePosition === "left" ? 5 : 4}
          order={isSmallScreen ? 1 : imagePosition === "left" ? 1 : 2} // Display image on the left if imagePosition is left, always left/top for small screens
          imageposition={imagePosition}
        >
          <StyledImg
            alt={title}
            src={`/static/images/${image}`}
            onClick={() => (window.location = link)}
          />
        </StyledImageGrid>

        {/* Text section */}
        <Grid
          item
          xs={12}
          sm={imagePosition === "left" ? 6 : 7}
          lg={imagePosition === "left" ? 7 : 8}
          container
          direction="column"
          justifyContent="center" // Center the text vertically
          order={isSmallScreen ? 2 : imagePosition === "left" ? 2 : 1} // Display text on the right if imagePosition is left, always right/bottom for small screens
        >
          {title && (
            <ReusableTitle
              title={title}
              isMainHeading={false}
              siteBranding={baseConfig.siteBranding}
              contentId={contentId}
            />
          )}

          <Typography
            paragraph
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {/* Button row */}
          <Grid container spacing={2} direction="row" sx={{ mt: 2 }}>
            {/* Learn More Button */}
            <Grid item>
              <Button
                component={LinkComponent}
                {...linkProps}
                variant="contained"
                size="medium"
                sx={{
                  width: 150, //width of learn more button
                  px: 1, // Add padding on the left and right of the text
                  backgroundColor: buttColor,
                  "&:hover": {
                    backgroundColor: buttHoverColor,
                  },
                }}
              >
                Learn More
              </Button>
            </Grid>
            {/* Get in Touch Button */}
            <Grid item>
              <Button
                startIcon={<ConnectWithoutContactIcon />}
                variant="contained"
                size="medium"
                sx={{
                  width: 180, //width of learn more button
                  backgroundColor: "#96858F",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
                href="/contact"
              >
                Get in Touch
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default BodyContentBlock;
