// kthStaffProfileConfigs.js
export const kthStaffProfileConfigs = {
  "roque-thuo": {
    name: "Roque Thuo",
    role: ["Attorney", "Engineer"],
    avatar: "/static/images/kama-thuo-pic.jpg",
    summary: [
      "Attorney Licensed in Arizona and California",
      "Registered to Practice Before the U.S. Patent and Trademark Office",
      "Professional Engineer (Electrical) Licensed in Arizona",
    ],
    introParagraphs: [
      "Roque Thuo advises clients on a range of technology transactions, including patent procurement, analysis, acquisition, license, and sale, principally related to analog and radio frequency (RF) circuits, wireless systems, and wireless standards. He also supports patent litigation teams with matters before federal district courts, the U.S. Patent and Trademark Office (USPTO), and the U.S. International Trade Commission (ITC).",
      "Roque understands the legal and regulatory aspects of several key wireless technologies, including Spectrum-Access System (SAS)-based Citizens Broadband Radio Service (CBRS) networks; wireless IoT technologies; LTE and 5G NR radio access networks; IEEE 802.11 Wireless LAN networks; and other wireless infrastructure components, including small cells, distributed antenna systems (DAS), and microwave backhaul links.",
      "Prior to studying law, Roque designed and verified analog and mixed-signal circuits for communication transceivers for Intel Corporation. He has work and research experience in the design and modeling of various radio frequency front-end modules and circuits, including antennas and passives, as well as in the design of point-to-point and point-to-multipoint links for broadband fixed wireless access and wireless backhaul. Roque is currently registered as a professional electrical engineer in the state of Arizona and is an Arizona-licensed low voltage communication contractor.",
    ],
    professionalRecognition: [
      "Selected for inclusion in Best Lawyers: Ones to Watch, 2021 – 2022",
    ],
    relatedEmployment: [
      "Perkins Coie, LLP, Patent Associate/Counsel, 2014 – 2022",
      "Intel Corporation, Hard-IP Analog Team, Analog Mixed-Signal Engineer, 2005 – 2013",
      "Microchip Technology Inc, Design Engineer, 2000 – 2005",
      "Microchip Technology Inc., Process Engineer, 1999 – 2000",
    ],
    experience: [
      {
        title: "AI TECHNOLOGY",
        description:
          "Analyzed AI patents to assess risk exposure and identify licensing opportunities. Drafted patents related to use of machine learning in wireless communication systems including for 5G NR network slicing and resource allocation.",
      },
      {
        title: "5G TECHNOLOGY",
        description:
          "Drafted 5G technology patents in areas including radio transceiver power amplifiers, multiple aspects of 5G NR baseband processors for user equipment and base station equipment, and multiple aspects of 5G Core Network functional nodes.",
      },
      {
        title: "RADIO EQUIPMENT",
        description:
          "Drafted patents in radio equipment front-end modules including integrated antennas for implanted medical devices and aerial vehicles.",
      },
      {
        title: "WIRELESS STANDARDS",
        description:
          "Analyzed quality of reading of hundreds of assets related to various wireless standards, including 3GPP 4G LTE, 5G NR standards, and IEEE standards, including 802.11 WLAN standards and 802.15 WPAN standards.",
      },
      {
        title: "CONTINUATION CLAIMS",
        description:
          "Advised clients on drafting continuation claims targeting various competitor products and wireless standards.",
      },
      {
        title: "WIRELESS PATENT PORTFOLIOS",
        description:
          "Evaluated large patent portfolios of hundreds of wireless patents for acquisition between large Fortune 500 companies at an aggregate valuation of over $1 billion. Experienced in developing monetization strategies targeting different vertical markets and diverse wireless patent portfolios.",
      },
    ],
    areasOfFocus: {
      practices: [
        "Patent Analytics, Strategy & Solutions",
        "Patent Prosecution & Portfolio Counseling",
        "Intellectual Property Law",
        "AI Technology Counseling",
        "Wireless Telecommunications Law",
      ],
      industries: [
        "5G Technology",
        "Artificial Intelligence & Machine Learning",
      ],
    },
    barAdmissions: [
      "Arizona",
      "California",
      "U.S. Patent and Trademark Office",
      "U.S. Court of Appeals for the Ninth Circuit",
      "U.S. District Court for the District of Arizona",
      "U.S. District Court for the Northern District of California",
    ],
    education: [
      "Arizona State University: J.D., summa cum laude, Deans Award in Recognition of Outstanding Performance, 2014.",
      "Arizona State University: Law, Science, and Technology Certificate with a specialization in Intellectual Property, 2014.",
      "Arizona State University: M.S., Electrical Engineering, 2001.",
      "Arizona State University: B.S., Electrical Engineering and Technology, summa cum laude, 1999.",
    ],
    skills: {
      fullStackDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python, PHP",
      ],
      scripting: ["PowerShell Scripting for Workflow Automation"],
      aiTools: ["AI Automation Development Tools"],
    },
    languages: ["Swahili"],
    news: [
      {
        title:
          "Best Lawyers® 2022 Recognized Roque Thuo for Inclusion in Best Lawyers: Ones to Watch",
        description:
          "Since its inception in 1983, Best Lawyers has become universally regarded as an important guide to legal excellence. The 28th edition of The Best Lawyers in America® (2022) includes attorneys in practice areas covering all 50 states and the District of Columbia, and inclusion in this year’s publication is based on more than 5.5 million detailed evaluations of attorneys by other attorneys. Best Lawyers: Ones to Watch recognizes attorneys who have been in practice 5–9 years for outstanding professional excellence in the United States. Because Best Lawyers is based on an exhaustive peer-review survey and attorneys are not required or allowed to pay a fee to be listed, inclusion in Best Lawyers is considered a singular honor.",
      },
      {
        title:
          "Roque Thuo Recognized by the Leadership Council on Legal Diversity",
        description:
          "Roque Thuo was recognized by the Leadership Council on Legal Diversity (LCLD) as part of the council’s Fellows and Pathfinder programs.",
      },
    ],
  },
  "ray-mutai": {
    name: "Ray Mutai",
    role: ["Engineer"],
    // avatar: "/static/images/kama-thuo-pic.jpg",
    summary: ["Wireless Engineer"],
    // introParagraphs: ["Ray is experienced in wireless systems and controls."],
  },
  "bill-kimutai": {
    name: "Bill Kimutai",
    role: ["Data Scientist"],
    summary: ["Data Analyst", "Full Stack & ML Developer"],
  },
  "brian-kibet": {
    name: "Brian Kibet",
    role: ["Engineer"],
    summary: ["Telecommunications Engineer", "Full Stack & ML Developer"],
  },

  "florence-wanjiko": {
    name: "Florence Wanjiko",
    role: ["Business Professional"],
    summary: ["Business Analyst"],
  },
  "purity-kwamboka": {
    name: "Purity Kwamboka",
    role: ["Business Professional"],
    summary: ["Client Admin"],
  },
  // Add more staff profiles below
};
