import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import AboutPatanalAi from "./AboutPatanalAi";
import PatanalAiHome from "./PatanalAiHome";
import DeviceSearch from "./DeviceSearch";
import DeviceDetailDashboard from "./DeviceDetailDashboard";
import DeviceClassification from "./DeviceClassification";
import ContactUsForm from "../common/ContactUsForm";
import PatentSearch from "./PatentSearch";
import PatentPriorArt from "./PatentPriorArt";
import PatentClassification from "./PatentClassification";
import PatentReview from "./PatentReview";
import StandardsCellular from "./StandardsCellular";
import StandardsLPWAN from "./StandardsLPWAN";
import StandardsWLAN from "./StandardsWLAN";
import SegmentSearch from "./SegmentSearch";
import SegmentDetail from "./SegmentDetail";
import RequireAuth from "../common/RequireAuth";
import PatanalaiAccount from "./PatanalaiAccount";

const PatanalAiRoutes = ({ baseConfig }) => {
  let routes = useRoutes([
    { path: "/aboutus", element: <AboutPatanalAi /> },
    {
      path: "/login",
      element: (
        <LoginForm
          termsLink="https://www.patentanalytics.com/terms-of-service"
          linkTarget="_blank"
        />
      ),
    },
    { path: "/logout", element: <Logout /> },
    {
      path: "/contact",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    {
      path: "/account",
      element: <PatanalaiAccount />,
    },
    //--------- Authenticated Paths --------------------------------------------------------
    {
      path: "/",
      element: (
        <RequireAuth>
          <PatanalAiHome />
        </RequireAuth>
      ),
    },
    //---- PATENTS -----
    {
      path: "/patents/patent-search",
      element: (
        <RequireAuth>
          <PatentSearch />
        </RequireAuth>
      ),
    },
    {
      path: "/patents/patent-search/:patentId",
      element: (
        <RequireAuth>
          <PatentSearch />
        </RequireAuth>
      ),
    },
    {
      path: "/patents/patent-prior-art",
      element: (
        <RequireAuth>
          <PatentPriorArt />
        </RequireAuth>
      ),
    },
    {
      path: "/patents/patent-classify",
      element: (
        <RequireAuth>
          <PatentClassification />
        </RequireAuth>
      ),
    },
    {
      path: "/patents/patent-review",
      element: (
        <RequireAuth>
          <PatentReview />
        </RequireAuth>
      ),
    },
    //---- DEVICES -----
    {
      path: "/devices/device-search",
      element: (
        <RequireAuth>
          <DeviceSearch />
        </RequireAuth>
      ),
    },
    {
      path: "/devices/device-detail",
      element: (
        <RequireAuth>
          <DeviceDetailDashboard />
        </RequireAuth>
      ),
    },
    {
      path: "/devices/device-detail/:devid",
      element: (
        <RequireAuth>
          <DeviceDetailDashboard />
        </RequireAuth>
      ),
    },
    {
      path: "/devices/device-classify",
      element: (
        <RequireAuth>
          <DeviceClassification />
        </RequireAuth>
      ),
    },
    //---- STANDARDS -----
    {
      path: "/standards/cellular",
      element: (
        <RequireAuth>
          <StandardsCellular />
        </RequireAuth>
      ),
    },
    {
      path: "/standards/lpwan",
      element: (
        <RequireAuth>
          <StandardsLPWAN />
        </RequireAuth>
      ),
    },
    {
      path: "/standards/wlan",
      element: (
        <RequireAuth>
          <StandardsWLAN />
        </RequireAuth>
      ),
    },
    //---- LICENSING -----
    {
      path: "/licensing/segment-search",
      element: (
        <RequireAuth>
          <SegmentSearch />
        </RequireAuth>
      ),
    },
    {
      path: "/licensing/segment-detail",
      element: (
        <RequireAuth>
          <SegmentDetail />
        </RequireAuth>
      ),
    },
  ]);
  return routes;
};

export default PatanalAiRoutes;
