// const environment = process.env.REACT_APP_ENVIRONMENT || "production";
const environment = window._env_.REACT_APP_ENVIRONMENT || "production";

//NOTE!!!!!!!!!!!!!!!!!!!!!!!!!!
// When add a new domain with authRequired path, you need to enable browser to treat http:// dev domain as secure
// chrome: chrome://flags/#unsafely-treat-insecure-origin-as-secure [add teh development domain]
// Edge: chrome://flags/#unsafely-treat-insecure-origin-as-secure
const domains = {
  production: {
    partsFinder: "parts-finder.rfwel.com",
    lpim: "lpim.rfwel.com",
    patanalai: "www.patanal.ai",
    patentanalytics: "www.patentanalytics.com",
    kamathuo: "www.kamathuo.com",
  },
  development: {
    partsFinder: "redev-parts-finder.rfwel.com",
    lpim: "redev-lpim.rfwel.com",
    patanalai: "redev.patanal.ai",
    patentanalytics: "redev.patentanalytics.com",
    kamathuo: "redev.kamathuo.com",
  },
};

export default domains[environment];
