import React from "react";
import { Typography, Container, Paper, Link } from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const AboutKamathuo = () => {
  SetDocumentMeta({
    title: "About Us | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  return (
    <Container maxWidth="md" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h5" gutterBottom>
          About Us
        </Typography>

        <Typography paragraph>
          Kama Thuo, PLLC focuses on Wireless Patents, AI, and Wireless
          Telecomm.
        </Typography>
      </Paper>
    </Container>
  );
};

export default AboutKamathuo;
