import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileXls, faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import PatanalaiWorksheetPatentTable from "./PatanalaiWorksheetPatentTable";
import PatanalaiWorksheetDeviceTable from "./PatanalaiWorksheetDeviceTable";
import PatanalaiWorksheetLicensingTable from "./PatanalaiWorksheetLicensingTable";
import { useWorksheet } from "../../../context/WorksheetContext";
import { exportToExcel, exportAllToExcel } from "./patanalaiExcelExportUtils";
import { exportToPDF, exportAllToPDF } from "./patanalaiPdfExportUtils";
import { useAuth } from "../../../context/AuthContext";
import RestrictedAccessPrompt from "../../common/RestrictedAccessPrompt";

const PatanalaiWorksheet = () => {
  const { user } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("patents");
  const { worksheet, removeFromWorksheet } = useWorksheet();

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  //-----------------------------------------------------------------------------------------
  // Render Worksheet Tab Content
  //-----------------------------------------------------------------------------------------
  const renderContent = () => {
    if (!user) {
      // If user is not authenticated, show restricted access prompt
      return <RestrictedAccessPrompt component={"Patanal.ai Worksheet"} />;
    }

    if (activeTab === "summary") {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFileXls} />}
            onClick={() => exportAllToExcel(worksheet)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export all to Excel
          </Button>

          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => exportAllToPDF(worksheet)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export all to PDF
          </Button>
        </Box>
      );
    } else if (!worksheet[activeTab] || worksheet[activeTab].length === 0) {
      return (
        <Alert severity="info" style={{ margin: 20 }}>
          There are no items saved to the {activeTab} worksheet.
        </Alert>
      );
    }

    const data = worksheet[activeTab];
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFileXls} />}
            onClick={() => exportToExcel(worksheet[activeTab], activeTab)}
            sx={{ width: "auto" }}
          >
            Export Excel
          </Button>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => exportToPDF(worksheet[activeTab], activeTab)}
            sx={{ ml: 2, width: "auto" }}
          >
            Export PDF
          </Button>
        </Box>
        {activeTab === "patents" ? (
          <PatanalaiWorksheetPatentTable
            patents={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        ) : activeTab === "devices" ? (
          <PatanalaiWorksheetDeviceTable
            devices={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        ) : (
          <PatanalaiWorksheetLicensingTable
            licensing={data}
            removeFromWorksheet={removeFromWorksheet}
          />
        )}
        <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            Click on "Export Excel" or "Export PDF" above to export the{" "}
            {activeTab} worksheet. Export all worksheets to an Excel workbook or
            PDF in the "Summary" tab.
          </Typography>
        </Box>
      </>
    );
  };

  //-----------------------------------------------------------------------------------------
  // Render View Worksheet Button and Dialog
  //-----------------------------------------------------------------------------------------

  return (
    <>
      <Button
        variant="text"
        onClick={() => setDialogOpen(true)}
        startIcon={<StorageIcon />}
        sx={{ mr: 4, color: "white" }}
      >
        Worksheet
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        {/* <DialogTitle>Worksheets</DialogTitle> */}
        <DialogContent>
          <Tabs value={activeTab} onChange={handleChangeTab} centered>
            <Tab label="Summary" value="summary" />
            <Tab label="Patents" value="patents" />
            <Tab label="Devices" value="devices" />
            <Tab label="Licensing" value="licensing" />
          </Tabs>
          {renderContent()}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatanalaiWorksheet;
