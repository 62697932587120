import React from "react";

//--------------------------------------------------------------------------------------
// Channel & Buy Button Labels
//--------------------------------------------------------------------------------------

export const BIGC = "bigc";
export const AMZ = "amz";
export const AMZ_SELLER_US = "amz_seller_us";
export const AMZ_SELLER_US_B2B = "amz_seller_us_b2b";
export const AMZ_FBA_US = "amz_fba_us";
export const AMZ_FBA_US_B2B = "amz_fba_us_b2b";
export const AMZ_SELLER_CA = "amz_seller_ca";
export const AMZ_US = "amz_us";
export const AMZ_CA = "amz_ca";
export const AMZ_AFF = "AMZ_AFF";
export const EBAY = "ebay";
export const WMT = "wmt";
export const NE = "newegg";
export const NE_BIZ = "newegg_biz";
export const ENGR = "ENGR";
export const HCOMP = "HCOMP";
export const PCOMP = "PCOMP";
export const FORUMS = "RfWeL-Forums";
export const WDI = "WDI";
export const GOOGLE = "GOOGLE";
export const ALL = "ALL";
export const JOOM = "JOOM";
export const PATANAL = "PATANAL";

// URL source tag when landing on BigC (to know that came from patanal JS)
export const SOURCE_TAG = "ptjs";

//--------------------------------------------------------------------------------------
// TEMPORARY DEVELOPMENT ISH -> MOVE OUT OF HERE
//--------------------------------------------------------------------------------------
// TEMP EXCHANGE RATE --> GEt from Config or API Service
/*export const FOREX_CAD_TO_USD = 0.72;*/

// TEMP styling -> Move to CSS
export const RFW_HORIZDOT = (
  //<hr style={{ borderBottom: "dotted 1px #000" }}></hr>
  <hr
    style={{
      borderTop: "2px dotted #999",
      marginTop: "0.9091em",
      paddingTop: "0.9091em",
    }}
  ></hr>
);

//--------------------------------------------------------------------------------------
// Price Thresholds
//--------------------------------------------------------------------------------------
export const FREE_SHIPPING_THRESHOLD = 99;
export const COMPE_THRESHOLD = 10; // Default price below competitor price, if above MARGIN_LOW
export const PRICE_MIN_TO_LIST_3RD_PARTY = 30; // Min price to flag item for listing in 3rd party listing channel
export const PRICE_MAX_TO_LIST_3RD_PARTY = 500; // Max price to flag item for listing in 3rd party listing channel
export const PRICE_ABOVE_BIGC = 10; // Default price of 3rd party listings above BigC price
export const PRICE_PERCENT_ABOVE_BIGC = 29; // Default percentage price of 3rd party listings above BigC price
export const PRICE_MIN_GOOGLE = 30; // Min price to list in Google shopping
export const PRICE_MAX_GOOGLE = 1000; // Max price to list in Google shopping
export const MARGIN_LOW_GOOGLE = 18; // Min margin to list in Google shopping
export const DEFAULT_THRESHOLD_PRICE_ABOVE_BIGC = 30; // Default threshold channel price should be above Bigc price
export const THRESHOLD_MARGIN_ABOVE_BIGC = 5; // if priced above, max channel margin % should be above bigc margin
//--------------------------------------------------------------------------------------
// Compe Module Configs
//--------------------------------------------------------------------------------------
// Compe Freshness Days & Audit frequency
export const DEFAULT_COMPE_FRESH_DAYS = 14;
export const MAX_COMPE_FRESH_DAYS = 60;
export const DEFAULT_COMPE_AUDIT_FREQUENCY = 60;
export const MAX_COMPE_AUDIT_FREQUENCY = 180;

// Inventory Data Freshness Days
export const DEFAULT_INVENTORY_FRESH_DAYS = 30;
export const MAX_INVENTORY_FRESH_DAYS = 90;

// Marketing Freshness Days
export const DEFAULT_MARKETING_FRESH_DAYS = 30;
export const MAX_MARKETING_FRESH_DAYS = 90;

//--------------------------------------------------------------------------------------
// Margin Labels
//--------------------------------------------------------------------------------------
export const MARGIN_LOW = 15;
export const MARGIN_HIGH = 45;
export const MARGIN_LOW_STR = "M.Low";
export const MARGIN_HIGH_STR = "M.High";
export const MARGIN_OK_STR = "M.OK";
export const UNK_STR = "-Unk-";
export const ON_CLEARANCE_STR = "ON_CLEARANCE";
export const MARGIN_LOW_ABS = 6; // report below margin fail if absolute margin below this number
export const MARGIN_HIGH_ABS = 15; // ignore above margin fail if absolute margin not even above this number
export const MINPRICE_FOR_MARGINHIGH = 30; // If price below this, do not report margin high

//--------------------------------------------------------------------------------------
// Channel Listing Status Labels
//--------------------------------------------------------------------------------------
export const lbl_LISTING_ACTIVE = "Listed";
export const lbl_LISTING_ACTIVE_PENDING_UPDATE = "Listed-Pending-Update";
export const lbl_LISTING_APPROVED = "Approved-for-Listing";
export const lbl_LISTING_INACTIVE_PENDING_UPDATE = "Disabled-Pending-Update";
export const lbl_LISTING_INACTIVE = "Disabled";
export const lbl_LISTING_DISABLED = "Disabled";
export const lbl_LISTING_SCHEDULED = "Scheduled";
export const lbl_LISTING_FFS = "FFS";
export const lbl_LISTING_ZBB = "No-Plan-to-List"; //zero-based-budget (an intel moniker for shelf it indefinitely)

//--------------------------------------------------------------------------------------
// General Purpose Functions/Utilities
//--------------------------------------------------------------------------------------
//@TODO - find better home for these
export function buildLink(url, label) {
  if (url)
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  if (label === "Link") return `--`;
  return label;
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatCurrency(value) {
  let amount = parseFloat(value);
  if (!isNaN(amount)) {
    return `$${amount.toFixed(2)}`;
  }
  return null;
}

export function formatPercentage(value) {
  let percent = parseFloat(value);
  if (!isNaN(percent)) {
    return `${percent.toFixed(1)}%`;
  }
  return null;
}

//--------------------------------------------------------------------------------------
// Brand Labels
//--------------------------------------------------------------------------------------
export const MAN_MISSING = "--UNSPECIFIED--";
export const MAN_AMANA = "Amana";
export const MAN_AMERSTD = "American Standard";
export const MAN_CARRIER = "Carrier";
export const MAN_COMFORTMAKER = "Comfortmaker";
export const MAN_DAIKIN = "Daikin";
export const MAN_FRIEDRICH = "Friedrich";
export const MAN_FRIGIDAIRE = "Frigidaire";
export const MAN_FUJITSU = "Fujitsu";
export const MAN_GOODMAN = "Goodman";
export const MAN_GREE = "Gree";
export const MAN_HITACHI = "Hitachi";
export const MAN_HONEYWELL = "Honeywell";
export const MAN_LENNOX = "Lennox";
export const MAN_LG = "LG";
export const MAN_LUXAIRE = "Luxaire";
export const MAN_MIDEA = "Midea";
export const MAN_MITSUBISHI = "Mitsubishi Electric";
export const MAN_MRCOOL = "MRCOOL";
export const MAN_PANASONIC = "Panasonic";
export const MAN_RHEEM = "Rheem";
export const MAN_RUUD = "Ruud";
export const MAN_SAMSUNG = "Samsung";
export const MAN_TRANE = "Trane";
export const MAN_VENSTAR = "Venstar";
export const MAN_YORK = "York";
