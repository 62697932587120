import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Chip,
  Link as MuiLink,
  Button,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ReusableTitle from "../common/ReusableTitle";
import { faTag } from "@fortawesome/pro-solid-svg-icons";

const KamathuoAiEvaluation = ({ baseConfig }) => {
  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="Evaluation of AI Applications"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faTag }}
        />

        <Box sx={{ mt: 2, mb: 4 }}>
          <ReusableTitle
            title="Overview of Kama Thuo, PLLC AI Evaluation Services"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Kama Thuo, PLLC provides comprehensive AI counseling services,
            including guidance on the legal and ethical sourcing of labeling
            services, and legal training on AI technologies and best practices.
            We also support clients in testing and evaluating AI outputs for
            bias and legal compliance. Our services also extend to ensuring AI
            systems adhere to privacy and data protection laws, offering
            strategic advice to navigate the complex regulatory landscape.
            Please{" "}
            <MuiLink component={RouterLink} to="/contact">
              contact us
            </MuiLink>{" "}
            to discuss your AI application evaluation needs.
          </Typography>

          <Grid container spacing={1} sx={{ mt: 2, ml: 3 }}>
            <Grid item>
              <Chip label="AI Bias" />
            </Grid>
            <Grid item>
              <Chip label="Regulatory Compliance" />
            </Grid>
            <Grid item>
              <Chip label="Rule 11 Investigations" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <ReusableTitle
            title="Regulatory Compliance & Governance Frameworks"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            The development of AI governance frameworks involves creating
            comprehensive policies, procedures, and guidelines to ensure the
            ethical, legal, and effective use of AI technologies within an
            organization.
          </Typography>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?category=ai-counseling"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default KamathuoAiEvaluation;
