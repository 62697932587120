import React from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import NotFound from "../NotFound";
import PatentAnalyticsHome from "./PatentAnalyticsHome";
import AboutPatentAnalytics from "./AboutPatentAnalytics";
import patentAnalyticsServices from "./patentAnalyticsServices";
import ContactUsForm from "../common/ContactUsForm";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import DmcaNotice from "./DmcaNotice";
import CookiePolicy from "./CookiePolicy";
import { useRoutes } from "react-router-dom";
import RequireAuth from "../common/RequireAuth";

const PatentAnalyticsRoutes = ({ baseConfig }) => {
  let routes = useRoutes([
    //--------- Public paths --------------------------------------------------------
    // {
    //   path: "/",
    //   element: <PatentAnalyticsHome />,
    // },
    { path: "/aboutus", element: <AboutPatentAnalytics /> },
    {
      path: "/login",
      element: <LoginForm termsLink="/terms-of-service" linkTarget="_blank" />,
    },
    //@TODO -- for now account form is just contactus form.
    {
      path: "/account",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/contact",
      element: <ContactUsForm baseConfig={baseConfig} />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy />,
    },
    {
      path: "/dmca-notice",
      element: <DmcaNotice />,
    },
    //--------- Authenticated Paths --------------------------------------------------------
    {
      path: "/",
      element: (
        <RequireAuth>
          <PatentAnalyticsHome baseConfig={baseConfig} />
        </RequireAuth>
      ),
    },
    {
      path: "/services",
      element: (
        <RequireAuth>
          <patentAnalyticsServices />
        </RequireAuth>
      ),
    },
  ]); // end useRoutes
  return routes;
};

export default PatentAnalyticsRoutes;
