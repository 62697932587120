import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { Box, IconButton, Grid, Link as MuiLink } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import HeaderCarouselItem from "../common/HeaderCarouselItem";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";

const KamathuoHome = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "The Patents, AI & Telecomm Law Firm",
    description:
      "Law firm specializing in engineering & technology legal services including patents, technology transfer agreements, patent licensing, artificial intelligence legal counseling, and wireless telecommunications law.",
    keywords:
      "engineering and technology patents, wireless patents, artificial intelligence law, wireless telecommunications law, patent drafting, patent analysis",
  });
  const [autoPlay, setAutoPlay] = useState(true);

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay);
  };

  // Define the content for the header carousel
  const carouselContent = [
    {
      name: "Engineering & Technology Patents",
      description:
        "AI, Wireless & High-Tech Patent Experts - Secure Your Innovations with Strategic Drafting, Prosecution, Analysis & Licensing..",
      image: "/kth-head-1-patents.png",
      link: "#patent-analysis",
    },
    {
      name: "Artificial Intelligence",
      description:
        "Providing strategic legal counsel for the development, evaluation, and integration of AI technologies, managing complexities, and mitigating risks.",
      image: "/kth-head-2-ai.png",
      link: "#ai",
    },
    {
      name: "Litigation Support",
      description:
        "Supporting patent litigation teams with matters before federal district courts, the U.S. Patent and Trademark Office (USPTO), and the U.S. International Trade Commission (ITC)",
      image: "/kth-head-3-litsupp.png",
      link: "#litigation",
    },
    {
      name: "Wireless Telecom",
      description:
        "Specializing in network infrastructure deployment, wireless technology standards, and compliance with federal and state regulations.",
      image: "/kth-head-4-telecomm.png",
      link: "#telecom",
    },
  ];

  // Define the content for the body
  const bodyContent = [
    {
      contentId: "patent-analysis",
      image: "/engr-tech-patents.png",
      title: "Engineering & Technology Patents",
      text: "Kama Thuo, PLLC combines deep engineering expertise with skilled legal counsel for comprehensive patent services. We specialize in drafting, prosecuting, analyzing, \
      and monetizing patent portfolios across cutting-edge technologies including: \
      <ul> \
      <li> Analog/RF circuits, baseband processors </li> \
      <li> Integrated antennas </li> \
      <li> Network infrastructure </li> \
      <li> High-tech electrical/computer systems </li> \
      <li> AI/ML software </li> \
      </ul>",
      imagePosition: "left",
      link: "/patents",
      linkExternal: false,
    },
    {
      contentId: "ai",
      image: "/kth-body-2-ai.png",
      title: "AI Counseling",
      text: "In the rapidly evolving field of AI, Kama Thuo, PLLC offers comprehensive legal services to help clients navigate the intricate landscape of AI development \
      and application. This includes strategic counsel on patenting AI technologies, managing AI risks, and ensuring compliance with privacy and other legal and ethical requirements. \
      Kama Thuo focuses on fostering innovation while protecting intellectual assets by helping clients leverage AI technologies to gain competitive advantages and achieve long-term success.",
      imagePosition: "right",
      link: "/ai",
      linkExternal: false,
    },
    {
      contentId: "litigation",
      image: "/kth-body-3-litsupp-tit.png",
      title: "District Court, IPR, ITC Support",
      text: "Kama Thuo, PLLC provides ligitation support in various forums including District Courts, USPTO IPR cases, and ITC investigations. Leveraging expertise in both engineering and law allows for sophisticated support in complex technology cases, providing strategic insights into claim construction, prior art search, and infringement/non-infringement arguments.",
      imagePosition: "left",
      link: "/patents",
      linkExternal: false,
    },
    {
      contentId: "telecom",
      image: "/kth-head-4-telecomm.png",
      title: "Wireless Telecommunications Law",
      text: "Kama Thuo, PLLC provides strategic counsel in wireless infrastructure transactions, spectrum advocacy, and regulatory issues. Understanding both technical aspects and legal frameworks allows Kama Thuo to effectively assist clients in navigating regulatory environments, licensing, and in compliance issues.",
      imagePosition: "right",
      link: "/telecom",
      linkExternal: false,
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12}>
        <Box position="relative">
          <Carousel autoPlay={autoPlay} interval={3500}>
            {carouselContent.map((item, i) => (
              <HeaderCarouselItem key={i} item={item} baseConfig={baseConfig} />
            ))}
          </Carousel>
          <Box
            position="absolute"
            top={0}
            right={0}
            p={4}
            zIndex="tooltip" // To ensure the button is above the carousel images
          >
            <IconButton
              onClick={toggleAutoPlay}
              size="small"
              sx={{ bgcolor: "rgba(255,255,255,0.7)" }}
            >
              {autoPlay ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KamathuoHome;
