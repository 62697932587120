import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Container,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { kthStaffProfileConfigs } from "./kthStaffProfileConfigs";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KamathuoProfessionals = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Professionals | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const [selectedRole, setSelectedRole] = useState("All");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const roles = [
    "All",
    ...new Set(
      Object.values(kthStaffProfileConfigs).flatMap((profile) => profile.role)
    ),
  ];

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const filteredProfiles =
    selectedRole === "All"
      ? Object.values(kthStaffProfileConfigs)
      : Object.values(kthStaffProfileConfigs).filter((profile) =>
          profile.role.includes(selectedRole)
        );

  const styles = {
    card: {
      display: "flex",
      flexDirection: isMobile ? "row" : "row",
      alignItems: isMobile ? "center" : "stretch",
      marginBottom: theme.spacing(2),
      width: "100%",
      height: isMobile ? "auto" : "300px", // Set a fixed height for non-mobile
    },
    avatar: {
      width: isMobile ? "100px" : "200px",
      height: isMobile ? "100px" : "200px",
      margin: isMobile ? "10px" : "20px",
      cursor: "pointer", // Add cursor to indicate clickable avatar
    },
    cardContent: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: isMobile ? "flex-start" : "center",
      textAlign: "left",
      padding: isMobile ? "10px" : "20px",
    },
    viewProfile: {
      marginTop: "auto",
      textAlign: "center",
      width: "100%",
    },
  };

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Our Professionals
        </Typography>
        <TextField
          select
          label="Filter by Role"
          value={selectedRole}
          onChange={handleRoleChange}
          fullWidth
          variant="outlined"
          margin="normal"
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Grid container spacing={4}>
        {filteredProfiles.map((profile) => (
          <Grid item xs={12} sm={6} md={6} key={profile.name}>
            <Card sx={styles.card}>
              <RouterLink
                to={`/professionals/${Object.keys(kthStaffProfileConfigs).find(
                  (key) => kthStaffProfileConfigs[key] === profile
                )}`}
              >
                <Avatar
                  alt={profile.name}
                  src={profile.avatar}
                  sx={styles.avatar}
                />
              </RouterLink>
              <CardContent sx={styles.cardContent}>
                <Typography variant="h6" component="h2">
                  {profile.name}
                </Typography>
                <Typography variant="body1" color="black">
                  {profile.role.join(" | ")}
                </Typography>
                <ul>
                  {profile.summary.map((item, i) => (
                    <li key={i}>
                      <Typography variant="body2" color="textSecondary">
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <Box sx={styles.viewProfile}>
                  <Typography
                    component={RouterLink}
                    to={`/professionals/${Object.keys(
                      kthStaffProfileConfigs
                    ).find((key) => kthStaffProfileConfigs[key] === profile)}`}
                    variant="body2"
                    color="primary"
                  >
                    View Profile
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default KamathuoProfessionals;
