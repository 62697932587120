import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

const PatanalaiWorksheetPatentTable = ({ patents, removeFromWorksheet }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Patent Number</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Publication Date</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {patents.map((patent) => (
        <TableRow key={patent.pn}>
          <TableCell>{patent.pn}</TableCell>
          <TableCell>{patent.title}</TableCell>
          <TableCell>{patent.publication_date}</TableCell>
          <TableCell>{patent.source}</TableCell>
          <TableCell>
            <Button onClick={() => removeFromWorksheet(patent.pn, "patents")}>
              Remove
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default PatanalaiWorksheetPatentTable;
