import React, { useEffect, useRef } from "react";

const KamathuoGoogleSearchBox = () => {
  const searchRef = useRef(null);

  useEffect(() => {
    // Function to load the Google CSE script
    const loadGoogleSearch = () => {
      const script = document.createElement("script");
      script.src = "https://cse.google.com/cse.js?cx=96e0ca3f1804d47c5";
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the script when the component mounts
    loadGoogleSearch();
  }, []);

  return <div ref={searchRef} className="gcse-search" />;
};

export default KamathuoGoogleSearchBox;
