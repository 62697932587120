import { kamathuoNavbarConfig } from "./kamathuo/kamathuoNavbarConfig";
import KamathuoRoutes from "./kamathuo/KamathuoRoutes";
import KamathuoHeader from "./kamathuo/KamathuoHeader";
import KamathuoFooter from "./kamathuo/KamathuoFooter";
import PatanalAiRoutes from "./patanalai/PatanalAiRoutes";
import PatanalaiWorksheet from "./patanalai/utils/PatanalaiWorksheet";
import { patanalAiNavbarConfig } from "./patanalai/patanalAiNavbarConfig";
import PatentAnalyticsRoutes from "./patentanalytics/PatentAnalyticsRoutes";
import { patentAnalyticsNavbarConfig } from "./patentanalytics/patentAnalyticsNavbarConfig";
import PatentAnalyticsHeader from "./patentanalytics/PatentAnalyticsHeader";
import PatentAnalyticsFooter from "./patentanalytics/PatentAnalyticsFooter";
import PartsFinderRoutes from "./parts-finder/PartsFinderRoutes";
import { partsFinderNavbarConfig } from "./parts-finder/partsFinderNavbarConfig";
import LpimRoutes from "./lpim/LpimRoutes";
import { lpimNavbarConfig } from "./lpim/lpimNavbarConfig";
import domains from "../domainCfg";
import BaseComponentSaas from "./BaseComponentSaas";
import BaseComponentWeb from "./BaseComponentWeb";

const baseConfigs = {
  // ----------- WWW.KAMATHUO.COM -----------
  [domains.kamathuo]: {
    BaseComponent: BaseComponentWeb,
    // title: "The Wireless Tech, AI & Telecomm Law Firm",
    // metaDescription:
    //   "Legal services in patent law, wireless technology, AI, and wireless telecommunications.",
    // metaKeywords:
    //   "wireless technology, AI, telecommunications, legal services, patents",
    siteBranding: {
      logo: "Kamathuo-logo-pe-250x100.png",
      priColor: "#96858F",
      secColor: "#EE86B7",
      tertColor: "#4ABDAC",
      buttColor: "#4ABDAC",
      buttHoverColor: "#66c9b9",
      headerTextColor: "#EE86B7",
    },
    faviconFolder: "kamathuo-favicon",
    navbarConfig: kamathuoNavbarConfig,
    RoutesComponent: KamathuoRoutes,
    welcomeText: "Welcome to KamaThuo, PLLC ...",
    HeaderComponent: KamathuoHeader,
    FooterComponent: KamathuoFooter,
    googleAnalyticsId: "G-HZBW2N1B4P",
  },
  // ----------- WWW.PATENTANALYTICS.COM -----------
  [domains.patentanalytics]: {
    BaseComponent: BaseComponentWeb,
    // title: "Wireless Patents Analytics",
    // metaDescription: "Patent analysis services and portfolio management.",
    // metaKeywords: "patent analysis, patent management",
    siteBranding: {
      logo: "patanal-logo-204x64.png",
      // logo: "patanal-logo-v2-204x64.png",
      priColor: "#94618E",
      secColor: "#9099A2",
      tertColor: "#88BBD6",
      buttColor: "#88BBD6",
      buttHoverColor: "#71A5C7",
      headerTextColor: "#94618E",
    },
    faviconFolder: "patentanalytics-favicon",
    navbarConfig: patentAnalyticsNavbarConfig,
    RoutesComponent: PatentAnalyticsRoutes,
    welcomeText: "Welcome to Patent Analytics ...",
    footerText: "Patent Analytics, Inc. All rights reserved.",
    HeaderComponent: PatentAnalyticsHeader,
    FooterComponent: PatentAnalyticsFooter,
    googleAnalyticsId: "G-STDCPX1PB3",
  },
  // ----------- WWW.PATANAL.AI ------------------
  [domains.patanalai]: {
    BaseComponent: BaseComponentSaas,
    WorksheetComponent: PatanalaiWorksheet,
    // title: "Patent Analysis AI Tool",
    // metaDescription:
    //   "AI-powered tool for efficient patent analysis and management.",
    // metaKeywords: "patent analysis, AI tool, patent management, legal ai tech",
    siteBranding: {
      // logo: "patanal-logo-reversed-204x64.png",
      logo: "patanal-logo-rev-1l-204x64.png",
      appBarColor: "#94618E",
      drawerColor: "#94618E",
    },
    faviconFolder: "patanalai-favicon",
    navbarConfig: patanalAiNavbarConfig,
    RoutesComponent: PatanalAiRoutes,
    welcomeText: "Patents AI",
    footerText:
      "Patanal is a registered trademark of <a href='https://www.patentanalytics.com' target='_blank'>Patent Analytics, Inc</a> in the United States. All rights reserved.",
    footerLegalLinks:
      "<a href='/aboutus'>About Us</a> | <a href='https://www.patentanalytics.com/terms-of-service' target='_blank'>Terms of Service</a> | <a href='https://www.patentanalytics.com/privacy-policy' target='_blank'>Privacy Policy</a> | <a href='https://www.patentanalytics.com/dmca-notice' target='_blank'>DMCA Notice</a> | <a href='/contact'>Contact Us</a>",
    googleAnalyticsId: "G-7N4CK7K6EK",
  },
  // ----------- PARTS-FINDER.RFWEL.COM -----------
  [domains.partsFinder]: {
    BaseComponent: BaseComponentSaas,
    // title: "Rfwel PartsFinder",
    // metaDescription: "Wireless device and HVAC Controls parts finder.",
    // metaKeywords: "LTE, NR, HVAC Controls, Antennas",
    siteBranding: {
      // logo: "rfwel_logo_204x64.jpg",
      logo: "",
      appBarColor: "#CC6633",
      drawerColor: "#CC6633",
    },
    faviconFolder: "lpim-favicon",
    navbarConfig: partsFinderNavbarConfig,
    RoutesComponent: PartsFinderRoutes,
    welcomeText: "Parts Finder Webtool",
    footerText: "Rfwel Engineering, LLC. All rights reserved.",
    footerLegalLinks: "",
    googleAnalyticsId: "G-9SSNVJM5FE",
  },
  // ----------- LPIM.RFWEL.COM -------------------
  [domains.lpim]: {
    BaseComponent: BaseComponentSaas,
    // title: "Rfwel LPIM",
    // metaDescription: "LPIM",
    // metaKeywords: "LPIM",
    siteBranding: {
      // logo: "rfwel_logo_204x64.jpg",
      logo: "",
      appBarColor: "#CC6633",
      drawerColor: "#CC6633",
    },
    faviconFolder: "lpim-favicon",
    navbarConfig: lpimNavbarConfig,
    RoutesComponent: LpimRoutes,
    welcomeText: "LPIM Webtool",
    footerText: "Rfwel Engineering, LLC. All rights reserved.",
    footerLegalLinks: "",
  },
};

export default baseConfigs;
